import { LoggerMessages } from '../tracking-bundle-internal/logger-messages'

export enum ExternalMessageType {
    InitSession,
    TabGaze,
    StopContent,
    StopGaze,
    CheckPermissions
}

interface ExternalMesssageDefault {
    type: ExternalMessageType
}

export interface InitSession extends ExternalMesssageDefault {
    type: ExternalMessageType.InitSession,
    data: {
        broker: {
            model: string,
            errorCorrection: string
        },
        panelist: string,
        panelistId: number,
        tmpSessionId: string,
        sessionLength: number
    }
}

export interface TabGaze extends ExternalMesssageDefault {
    type: ExternalMessageType.TabGaze,
    data: any
}

export interface StopContent extends ExternalMesssageDefault {
    type: ExternalMessageType.StopContent
}

export interface StopGaze extends ExternalMesssageDefault {
    type: ExternalMessageType.StopGaze
}

export interface CheckPermissions extends ExternalMesssageDefault {
    type: ExternalMessageType.CheckPermissions
}

export type ExternalMessage = InitSession | TabGaze | StopContent | StopGaze | CheckPermissions

/* ------ */

export enum InternalMessageType {
    InternalGaze,
    Logger,
    EndSession,
    QualityHalt
}

interface InternalMessageDefault {
    type: InternalMessageType
}

export interface InternalGaze extends InternalMessageDefault {
    type: InternalMessageType.InternalGaze,
    data: any //TODO: SHOULD DEFINE GAZE DATA
}

export interface Logger extends InternalMessageDefault {
    type: InternalMessageType.Logger,
    data: LoggerMessages
}

export interface EndSession extends InternalMessageDefault {
    type: InternalMessageType.EndSession,
}

export interface QualityHalt extends InternalMessageDefault {
    type: InternalMessageType.QualityHalt,
}

export type InternalMessage = InternalGaze | Logger | EndSession  | QualityHalt