import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Image, Divider, Link } from "@heroui/react";
import { isDesktop, isIOS } from "react-device-detect";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import GooglePlayLogo from "../../assets/Google_Play_Store_badge_EN.svg";
import AppStoreLogo from "../../assets/Download_on_the_App_Store_RGB_blk.svg";

const InstallMobileApp = () => {
  return (
    <div className="flex flex-col gap-8">
      {isDesktop ? (
        <p>
          Earn more points by completing sessions on your phone! Scan the
          relevant QR code on your device to download our app.
        </p>
      ) : (
        <p>Start a session using our app.</p>
      )}
      {(!isIOS || isDesktop) && (
        <div className="flex w-full justify-between items-center">
          <Link
            href="https://play.google.com/store/apps/details?id=com.lumenresearch.focusproject"
            isDisabled={isDesktop}
            className="opacity-100"
          >
            <Image width={200} alt="Google play" src={GooglePlayLogo} />
          </Link>
          {isDesktop && (
            <>
              <ArrowLongRightIcon width={50} />
              <QRCodeCanvas
                size={200}
                value="https://play.google.com/store/apps/details?id=com.lumenresearch.focusproject"
              />
            </>
          )}
        </div>
      )}
      {isDesktop && <Divider />}
      {(isIOS || isDesktop) && (
        <div className="flex w-full justify-between items-center">
          <Link
            href="https://apps.apple.com/us/app/the-focus-project/id1537120635"
            isDisabled={isDesktop}
            className="opacity-100"
          >
            <Image width={200} alt="App store" src={AppStoreLogo} />
          </Link>
          {isDesktop && (
            <>
              <ArrowLongRightIcon width={50} />
              <QRCodeCanvas
                size={200}
                value="https://apps.apple.com/us/app/the-focus-project/id1537120635"
              />
            </>
          )}
        </div>
      )}
      {!isDesktop && (
        <p>Or login on a computer to starts a session via the browser.</p>
      )}
    </div>
  );
};

export default InstallMobileApp;
