// src/index.ts
import { useRef, useState, useEffect } from "react";
import { useIsHydrated } from "@heroui/react-utils";
import { useSafeLayoutEffect } from "@heroui/use-safe-layout-effect";
function useImage(props = {}) {
  const { onLoad, onError, ignoreFallback } = props;
  const isHydrated = useIsHydrated();
  const imageRef = useRef(isHydrated ? new Image() : null);
  const [status, setStatus] = useState("pending");
  useEffect(() => {
    if (!imageRef.current)
      return;
    imageRef.current.onload = (event) => {
      flush();
      setStatus("loaded");
      onLoad == null ? void 0 : onLoad(event);
    };
    imageRef.current.onerror = (error) => {
      flush();
      setStatus("failed");
      onError == null ? void 0 : onError(error);
    };
  }, [imageRef.current]);
  const flush = () => {
    if (imageRef.current) {
      imageRef.current.onload = null;
      imageRef.current.onerror = null;
      imageRef.current = null;
    }
  };
  useSafeLayoutEffect(() => {
    if (isHydrated) {
      setStatus(setImageAndGetInitialStatus(props, imageRef));
    }
  }, [isHydrated]);
  return ignoreFallback ? "loaded" : status;
}
function setImageAndGetInitialStatus(props, imageRef) {
  const { loading, src, srcSet, crossOrigin, sizes, ignoreFallback } = props;
  if (!src)
    return "pending";
  if (ignoreFallback)
    return "loaded";
  const img = new Image();
  img.src = src;
  if (crossOrigin)
    img.crossOrigin = crossOrigin;
  if (srcSet)
    img.srcset = srcSet;
  if (sizes)
    img.sizes = sizes;
  if (loading)
    img.loading = loading;
  imageRef.current = img;
  if (img.complete && img.naturalWidth) {
    return "loaded";
  }
  return "loading";
}
var shouldShowFallbackImage = (status, fallbackStrategy) => status !== "loaded" && fallbackStrategy === "beforeLoadOrError" || status === "failed" && fallbackStrategy === "onError";
export {
  shouldShowFallbackImage,
  useImage
};
