import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@heroui/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RefetchFunction } from "axios-hooks";

import { DetailsResponse, IDoc, updateTerms, useTerms } from "@/api/index";
import { useAppSelector } from "@/hooks/store";
import { useAlertContext } from "@/contexts/alertContext";
import ErrorMessage from "@/pages/errorMessage";
import Loading from "../loading/loading";

interface TermsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  refetch?: RefetchFunction<any, DetailsResponse>;
}

const TermsModal: React.FC<TermsModalProps> = ({
  isOpen,
  setIsOpen,
  refetch,
}) => {
  const { panelistId, accessCode, loggedIn } = useAppSelector(
    (state) => state.auth,
  );
  const { addAlert } = useAlertContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const [{ data, loading }] = useTerms(accessCode, panelistId, loggedIn);
  const [terms, setTerms] = useState<IDoc>();

  const onAgreeSubmit = async () => {
    setIsDisabled(true);
    try {
      await updateTerms(accessCode, panelistId);
      refetch?.();
      setIsOpen(false);
    } catch (e) {
      addAlert({
        message: e?.message,
        type: "error",
        timeout: 5,
      });
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (!data?.success) return;

    const termsDocs = data.doc_list?.find(
      ({ doc_name }) => doc_name === "Ts&Cs",
    );

    setTerms(termsDocs);
  }, [data]);

  const getModalContent = () => {
    if (!loggedIn && loading) {
      return (
        <div className="h-screen">
          <Loading />
        </div>
      );
    }

    if (!loggedIn && !terms) {
      return (
        <div className="h-screen">
          <ErrorMessage />
        </div>
      );
    }

    return (
      <>
        <ModalHeader className="flex flex-col gap-1">
          {loggedIn ? "Action Required: Accept Updated " : ""}
          {terms?.doc_name_formatted}
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-8">
            <p className="whitespace-pre-wrap">{terms?.text}</p>
            {loggedIn && (
              <Button
                disabled={isDisabled}
                color="primary"
                onPress={onAgreeSubmit}
              >
                Agree
              </Button>
            )}
          </div>
        </ModalBody>
      </>
    );
  };

  if (loggedIn && !terms) return null;

  return (
    <Modal
      isOpen={isOpen}
      size="5xl"
      isDismissable={loggedIn}
      scrollBehavior="inside"
      hideCloseButton={loggedIn}
      onClose={() => setIsOpen(false)}
      isKeyboardDismissDisabled={loggedIn}
    >
      <ModalContent>{getModalContent()}</ModalContent>
    </Modal>
  );
};

export default TermsModal;
