"use client";

// src/base/listbox-item-base.tsx
import { BaseItem } from "@heroui/aria-utils";
var ListboxItemBase = BaseItem;
var listbox_item_base_default = ListboxItemBase;

export {
  listbox_item_base_default
};
