"use client";

// src/base/menu-item-base.tsx
import { BaseItem } from "@heroui/aria-utils";
var MenuItemBase = BaseItem;
var menu_item_base_default = MenuItemBase;

export {
  menu_item_base_default
};
