import React, { useEffect, useState } from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { useOutletContext } from "react-router-dom";
import { Button, Select, SelectItem } from "@heroui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { SessionExternalContext } from "@/types/session";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import { useAlertContext } from "@/contexts/alertContext";
import Loading from "@/components/loading/loading";
import Header from "@/components/header/header";
import { IDomain, startWildRequest, usePromptList } from "../../../api";
import { ErrorClass } from "../../../utils/errorFormat";
import extensionSendMessage from "../../../utils/extensionCom";

const DEFAULT_DOMAIN: IDomain = {
  domain_name_formatted: "Google",
  domain_name: "https://google.com/",
};

interface ISessionExternalForm {
  domain: string;
}

const SessionExternal = () => {
  const {
    broker,
    panelist,
    tmpSessionId,
    panelistId,
    accessCode,
    errorHandle,
    setIsLoading,
  } = useOutletContext<SessionExternalContext>();
  const { handleSubmit, control } = useForm<ISessionExternalForm>();
  const { addAlert } = useAlertContext();
  const [canClick, setCanClick] = useState(true);
  const { getError } = useSessionErrorContext();
  const [{ data, loading }] = usePromptList(accessCode, panelistId);
  const [availableDomains, setAvailableDomains] = useState<IDomain[]>([]);

  useEffect(() => {
    if (!data?.success || loading) return;
    setAvailableDomains(
      data.domain_list?.length ? data.domain_list : [DEFAULT_DOMAIN],
    );
  }, [loading, data]);

  const onSubmit: SubmitHandler<ISessionExternalForm> = async ({ domain }) => {
    setIsLoading(true);
    setCanClick(false);

    try {
      broker.saveModel("model", "errorCorrection");
      if (broker.trackersController) {
        await broker.turnOffCamera();
      }
      const model = localStorage.getItem("model");
      const errorCorrection = localStorage.getItem("errorCorrection");
      if (model && errorCorrection) {
        const m: PanelMessages.InitSession = {
          type: PanelMessages.ExternalMessageType.InitSession,
          data: {
            broker: {
              model,
              errorCorrection,
            },
            panelist,
            panelistId,
            tmpSessionId,
            sessionLength: 600000, // TODO: SHOULD BE FROM STATE,
          },
        };
        const canStart = await extensionSendMessage(m);
        if (canStart) {
          await startWildRequest(accessCode, tmpSessionId, panelistId);
          window.location.href = domain as string;
        } else {
          const message = getError(ErrorClass.EXT_INIT_FAIL).safeMessage;
          addAlert({ type: "error", message, timeout: 5 });
          throw new Error(message);
        }
      } else {
        const message = getError(ErrorClass.MISSING_MODEL).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }
    } catch (e) {
      errorHandle(e);
    }
    setIsLoading(false);
  };

  if (!availableDomains?.length) return <Loading showLabel={false} />;

  return (
    <>
      <Header size="h2"> How to earn points in your session?</Header>
      <ol className="mb-8 list-decimal list-inside">
        <li>
          Select a website from the suggested options below to start your
          session.
        </li>
        <li>
          For the duration of the session browse the internet as you usually
          would
        </li>
        <li>
          After 10 minutes you will be automatically redirected in a new tab to
          a validation screen
        </li>
        <li>Complete a successful validation in order to secure your points</li>
      </ol>
      <p className="mb-8">Your session will last 10 minutes.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="domain"
          control={control}
          render={({ field }) => (
            <Select
              label="Select an website"
              className="mb-5"
              variant="bordered"
              {...field}
              autoFocus
              classNames={{
                trigger: [
                  "data-[focus=true]:border-primary",
                  "data-[open=true]:border-primary",
                ],
              }}
              defaultSelectedKeys={[availableDomains[0].domain_name]}
              disallowEmptySelection
            >
              {availableDomains.map(
                ({ domain_name, domain_name_formatted }) => (
                  <SelectItem
                    aria-label={domain_name_formatted}
                    key={domain_name}
                  >
                    {domain_name_formatted}
                  </SelectItem>
                ),
              )}
            </Select>
          )}
        />
        <Button color="secondary" disabled={!canClick} type="submit">
          Start Session
        </Button>
      </form>
    </>
  );
};

export default SessionExternal;
