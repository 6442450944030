import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from "@heroui/react";

import { SessionCalibrationContext, SessionRoute } from "@/types/session";
import { setCalibrationTime } from "@/store/reducers/session";
import { ErrorClass } from "@/utils/errorFormat";
import { useAlertContext } from "@/contexts/alertContext";
import { useSessionErrorContext } from "@/contexts/sessionErrorContext";
import Header from "@/components/header/header";

const SessionCalibration = () => {
  const { broker, dispatch, calibrationRef, trackerRef, errorHandle } =
    useOutletContext<SessionCalibrationContext>();
  const { addAlert } = useAlertContext();
  const { getError } = useSessionErrorContext();
  const navigate = useNavigate();

  const cont = () => {
    navigate(SessionRoute.VALIDATION);
  };

  const calibrate = async () => {
    try {
      const div = calibrationRef.current;
      const trackerDiv = trackerRef.current;
      if (!div || !trackerDiv) {
        const message = getError(ErrorClass.CALIBRATION_DIV).safeMessage;
        addAlert({ type: "error", message, timeout: 5 });
        throw new Error(message);
      }

      dispatch(setCalibrationTime());
      await broker.calibrate(div, 50000);
      broker.trackersController.setVideoDiv(trackerDiv);
      broker.trackersController._videoView.render();
      cont();
    } catch (e) {
      const div = calibrationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      errorHandle(e);
    }
  };
  return (
    <>
      <Header size="h2">Step 3: Calibration</Header>
      <div className="flex flex-col gap-5 mb-8">
        <p>On the next page follow the blue dot with your eyes.</p>
        <p>Try to keep your head still.</p>
      </div>
      <Button color="secondary" type="submit" onPress={calibrate}>
        Continue
      </Button>
    </>
  );
};

export default SessionCalibration;
