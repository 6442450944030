"use client";
import {
  useNavbarContext
} from "./chunk-IGERPFKH.mjs";
import {
  menuVariants
} from "./chunk-UJDFI5KD.mjs";

// src/navbar-menu.tsx
import { forwardRef } from "@heroui/system";
import { useDOMRef } from "@heroui/react-utils";
import { clsx, dataAttr } from "@heroui/shared-utils";
import { AnimatePresence, LazyMotion, m } from "framer-motion";
import { mergeProps } from "@react-aria/utils";
import { Overlay } from "@react-aria/overlays";
import { jsx } from "react/jsx-runtime";
var domAnimation = () => import("@heroui/dom-animation").then((res) => res.default);
var NavbarMenu = forwardRef((props, ref) => {
  var _a, _b;
  const { className, children, portalContainer, motionProps, style, ...otherProps } = props;
  const domRef = useDOMRef(ref);
  const { slots, isMenuOpen, height, disableAnimation, classNames } = useNavbarContext();
  const styles = clsx(classNames == null ? void 0 : classNames.menu, className);
  if (disableAnimation) {
    if (!isMenuOpen)
      return null;
    return /* @__PURE__ */ jsx(Overlay, { portalContainer, children: /* @__PURE__ */ jsx(
      "ul",
      {
        ref: domRef,
        className: (_a = slots.menu) == null ? void 0 : _a.call(slots, { class: styles }),
        "data-open": dataAttr(isMenuOpen),
        style: {
          "--navbar-height": typeof height === "number" ? `${height}px` : height
        },
        ...otherProps,
        children
      }
    ) });
  }
  return /* @__PURE__ */ jsx(AnimatePresence, { mode: "wait", children: isMenuOpen ? /* @__PURE__ */ jsx(Overlay, { portalContainer, children: /* @__PURE__ */ jsx(LazyMotion, { features: domAnimation, children: /* @__PURE__ */ jsx(
    m.ul,
    {
      ref: domRef,
      layoutScroll: true,
      animate: "enter",
      className: (_b = slots.menu) == null ? void 0 : _b.call(slots, { class: styles }),
      "data-open": dataAttr(isMenuOpen),
      exit: "exit",
      initial: "exit",
      style: {
        "--navbar-height": typeof height === "number" ? `${height}px` : height,
        ...style
      },
      variants: menuVariants,
      ...mergeProps(motionProps, otherProps),
      children
    }
  ) }) }) : null });
});
NavbarMenu.displayName = "HeroUI.NavbarMenu";
var navbar_menu_default = NavbarMenu;

export {
  navbar_menu_default
};
