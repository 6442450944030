"use client";
import {
  useDropdownContext
} from "./chunk-BV7BCS3N.mjs";

// src/dropdown-trigger.tsx
import { PopoverTrigger } from "@heroui/popover";
import { jsx } from "react/jsx-runtime";
var DropdownTrigger = (props) => {
  const { getMenuTriggerProps } = useDropdownContext();
  const { children, ...otherProps } = props;
  return /* @__PURE__ */ jsx(PopoverTrigger, { ...getMenuTriggerProps(otherProps), children });
};
DropdownTrigger.displayName = "HeroUI.DropdownTrigger";
var dropdown_trigger_default = DropdownTrigger;

export {
  dropdown_trigger_default
};
