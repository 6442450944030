"use client";
import {
  CheckboxIcon
} from "./chunk-5E6Z6LLK.mjs";
import {
  useCheckbox
} from "./chunk-5JTA22ZA.mjs";

// src/checkbox.tsx
import { forwardRef } from "@heroui/system";
import { cloneElement } from "react";
import { jsx, jsxs } from "react/jsx-runtime";
var Checkbox = forwardRef((props, ref) => {
  const {
    Component,
    children,
    icon = /* @__PURE__ */ jsx(CheckboxIcon, {}),
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getIconProps,
    getLabelProps
  } = useCheckbox({ ...props, ref });
  const clonedIcon = typeof icon === "function" ? icon(getIconProps()) : cloneElement(icon, getIconProps());
  return /* @__PURE__ */ jsxs(Component, { ...getBaseProps(), children: [
    /* @__PURE__ */ jsx("input", { ...getInputProps() }),
    /* @__PURE__ */ jsx("span", { ...getWrapperProps(), children: clonedIcon }),
    children && /* @__PURE__ */ jsx("span", { ...getLabelProps(), children })
  ] });
});
Checkbox.displayName = "HeroUI.Checkbox";
var checkbox_default = Checkbox;

export {
  checkbox_default
};
