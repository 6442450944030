import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@heroui/react";

import { useAppSelector } from "@/hooks/store";
import Loading from "@/components/loading/loading";
import { useFAQs } from "../api";
import ErrorMessage from "./errorMessage";

const FAQs = () => {
  const { panelistId, accessCode, loggedIn } = useAppSelector(
    (state) => state.auth,
  );

  const [{ data, loading }] = useFAQs(accessCode, panelistId, loggedIn);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    if (!data?.success) return;

    const questionCategories = Array.from(
      new Set(data.faq_list.map((item) => item.question_category)),
    );

    setCategories(questionCategories);
  }, [data]);

  if (loading) return <Loading />;
  if (!data?.success) return <ErrorMessage />;

  return (
    <section className="mb-10">
      <h1 className="font-semibold mb-5 text-2xl">
        Frequently Asked Questions
      </h1>

      <Accordion variant="bordered" className="font-semibold">
        {categories?.map((category) => (
          <AccordionItem
            key={category}
            aria-label={`Accordion ${category}`}
            title={category}
          >
            <div className="font-normal">
              <Accordion variant="light">
                {data.faq_list.map(
                  ({ question, answer, question_category }) => {
                    if (question_category !== category) return null;

                    return (
                      <AccordionItem
                        key={question}
                        aria-label={`Accordion 2 ${question}`}
                        title={question}
                      >
                        <div className="flex flex-col gap-4 whitespace-pre-wrap">
                          {answer}
                        </div>
                      </AccordionItem>
                    );
                  },
                )}
              </Accordion>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export default FAQs;
