import { Link } from "@heroui/react";
import React from "react";

const InstallChrome = () => {
  return (
    <div className="flex flex-col gap-4">
      <p>
        The extension to participate in web based research sessions only
        supports Google Chrome. Please switch to Chrome to continue.
      </p>
      <p>
        Install Google Chrome via their official website{" "}
        <Link
          isExternal
          showAnchorIcon
          href="https://www.google.com/intl/en_uk/chrome/"
        >
          here
        </Link>
        .
      </p>
    </div>
  );
};

export default InstallChrome;
