import { createRoot } from "react-dom/client";
import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as RBProvider, ErrorBoundary } from "@rollbar/react";
import { Provider } from "react-redux";
import { HeroUIProvider } from "@heroui/react";
import { store, persistor } from "./store/store";
import App from "./App";
import rollbarConfig from "./utils/rollbar";
import ddInit from "./utils/datadog";
import AlertContextProvider from "./contexts/alertContext";

// Initialise datadog
ddInit();

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container);

root.render(
  <RBProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HeroUIProvider>
            <AlertContextProvider>
              <App />
            </AlertContextProvider>
          </HeroUIProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </RBProvider>,
);
